import { useState } from "react";
import "./App.css";
import Header from "./components/Header/Header";
import SocialIcon from "./components/SocialIcon/SocialIcon";
import Main from "./components/Main/Main";

function App() {
  const [isMainBlurred, setIsMainBlurred] = useState(false);

  return (
    <div className="App" style={{ fontFamily: "NTR, sans-serif" }}>
      <Header setMainBlur={setIsMainBlurred} />
      <SocialIcon isMobile={false} />
      <Main isBlurred={isMainBlurred} />
    </div>
  );
}

export default App;
