import React, { useState, useEffect } from "react";
import "./Header.css";
import SocialIcon from "../SocialIcon/SocialIcon";

export default function Header({ setMainBlur }) {
  const [isActive, setIsActive] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Add scrolled class when page is scrolled more than 50px
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsActive(!isActive);
    setMainBlur(!isActive);
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setIsActive(false);
      setMainBlur(false);
    }
  };

  return (
    <header className={`header ${isScrolled ? "scrolled" : ""}`}>
      <div className="logo">
        {" "}
        <a onClick={() => scrollToSection("home")}>CA</a>
      </div>
      <nav className={`nav ${isActive ? "active" : ""}`}>
        {/* <div className="nav_close" onClick={toggleMenu}>
          <div className="nav_close-line"></div>
          <div className="nav_close-line"></div>
        </div> */}
        <ul className="nav_list">
          <li className="nav_list-item">
            <a onClick={() => scrollToSection("home")}>home</a>
          </li>
          <li className="nav_list-item">
            <a onClick={() => scrollToSection("about")}>about</a>
          </li>
          <li className="nav_list-item">
            <a onClick={() => scrollToSection("experience")}>experience</a>
          </li>
          <li className="nav_list-item">
            <a onClick={() => scrollToSection("projects")}>projects</a>
          </li>
        </ul>
        <div className="nav_social">
          <SocialIcon isMobile={true} />
        </div>
      </nav>
      <div
        className={`hamburger ${isActive ? "active" : ""}`}
        onClick={toggleMenu}
      >
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </div>
    </header>
  );
}
