import "./About.css";
import aboutImg from "../../assests/images/dp.jpg";
import { useEffect, useRef } from "react";

export default function About() {
  const aboutRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      {
        threshold: 0.2,
        rootMargin: "-50px",
      }
    );

    if (aboutRef.current) {
      observer.observe(aboutRef.current);
    }

    return () => {
      if (aboutRef.current) {
        observer.unobserve(aboutRef.current);
      }
    };
  }, []);

  return (
    <div className="about" ref={aboutRef}>
      <div className="about_title">
        <h3>/about</h3>
      </div>
      <div className="about_content">
        <div className="bio_grid">
          <p className="about_info">
            <span>
              Hello! My name is Catherine, and I'm a software developer with
              over 2 years of hands-on experience in building websites and web
              applications across various sectors using modern frameworks and
              tools. <br /> I’m currently working on a personal project,
              TubeTrove, a platform designed for YouTube lovers, creators, and
              content explorers, aimed at simplifying the way they save and
              organize their favorite channels.
            </span>
            <span>
              I thrive in collaborative environments and I am always eager to
              learn and grow.
            </span>

            <span>
              Here are a few technologies I've been working with recently:
            </span>
          </p>
          <ul className="tech-stack">
            <div className="stack_item">
              <li>Typescript</li>
            </div>
            <div className="stack_item">
              <li>Node.js</li>
            </div>
            <div className="stack_item">
              <li>MongoDB</li>
            </div>
            <div className="stack_item">
              <li>MySQL</li>
            </div>
            <div className="stack_item">
              <li>Python</li>
            </div>
            <div className="stack_item">
              <li>React.js</li>
            </div>
            <div className="stack_item">
              <li>Java</li>
            </div>

            <div className="stack_item">
              <li>C++</li>
            </div>
          </ul>
          <p className="about_info">
            <span>
              Outside of work, I have a keen interest in the latest developments
              in science and technology and I share my knowledge and passion
              through my Medium page and other social platforms, where I
              demonstrate how to build full-stack applications.
            </span>
          </p>
        </div>
        <div className="about_img">
          <img src={aboutImg} alt="about" />
        </div>
      </div>
    </div>
  );
}
