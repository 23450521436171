import "./Home.css";

export default function Home() {
  return (
    <div className="home">
      <div className="home_name" style={{ fontFamily: "NTR, sans-serif" }}>
        <p className="home_name_hi">Hi, my name is</p>
        <h1>Catherine</h1>
        <h2>Anozie</h2>
      </div>
      <div className="home_description">
        <p>
          I'm a software engineer based in United Kingdom with a solid
          background in both front-end and back-end development, I specialize in
          building high-scale, high-impact products.
        </p>
      </div>

      <div className="home_button">
        <a
          href="mailto:nayaanozie@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button>Get in touch</button>
        </a>
      </div>
    </div>
  );
}
