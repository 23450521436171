import "./SocialIcon.css";
import mail from "../../assests/icons/mail-alt-3-svgrepo-com.svg";
import linkedIn from "../../assests/icons/linkedin-svgrepo-com.svg";
import github from "../../assests/icons/github-svgrepo-com.svg";
import write from "../../assests/icons/write-svgrepo-com.svg";

export default function SocialIcon({ isMobile }) {
  const containerClass = isMobile ? "mobile-icon-container" : "icon-container";

  const socialLinks = [
    {
      icon: mail,
      alt: "mail",
      url: "mailto:nayaanozie@gmail.com",
      title: "Email Me",
    },
    {
      icon: linkedIn,
      alt: "linkedin",
      url: "https://www.linkedin.com/in/catherineanozie/",
      title: "LinkedIn Profile",
    },
    {
      icon: github,
      alt: "github",
      url: "https://github.com/nayaA-io",
      title: "GitHub Profile",
    },
    {
      icon: write,
      alt: "write",
      url: "https://medium.com/@nayaanozie",
      title: "Blog/Portfolio",
    },
  ];

  return (
    <div className="social_icon">
      <div className={containerClass}>
        <ul className="social_icon-list">
          {socialLinks.map((social, index) => (
            <li key={index} className="social_icon-list-item">
              <a
                href={social.url}
                target="_blank"
                rel="noopener noreferrer"
                title={social.title}
              >
                <img src={social.icon} alt={social.alt} />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
