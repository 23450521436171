import "./Main.css";
import Home from "../../pages/Home/Home";
import About from "../../pages/About/About";
import Experience from "../../pages/Experience/Experience";
import Projects from "../../pages/Projects/Projects";
import Footer from "../Footer/Footer";

export default function Main({ isBlurred }) {
  return (
    <div className={`main ${isBlurred ? "blur" : ""}`}>
      <section id="home">
        <Home />
      </section>
      <section id="about">
        <About />
      </section>
      <section id="experience">
        <Experience />
      </section>
      <section id="projects">
        <Projects />
      </section>
      <Footer />
    </div>
  );
}
