import { useState, useEffect, useRef } from "react";
import "./Experience.css";

export default function Experience() {
  const [activeTab, setActiveTab] = useState(0);
  const experienceRef = useRef(null);

  const jobs = [
    {
      company: "Treloars Trust",
      title: "Healthcare Support Worker",
      duration: "Nov 2022 - Present",
      description: [
        "Worked within cross-functional teams and collaborated to ensure comprehensive support was provided to residents, focusing on daily living assistance, personal care, and enhancing their social interactions to improve overall well-being.",
        "Utilized strong communication and attention to detail to assist with medication administration, including oral and gastro routes, ensuring proper dosage and adherence to medical protocols.",
        "Demonstrated effective teamwork and problem-solving by working collaboratively with students to foster their independence and help them develop essential life skills.",
        "Partnered with the dietitians, applying analytical thinking and collaboration skills to assess and enhance the nutritional and feeding needs of residents, contributing to their overall health.",
        "Acted as a key worker, leveraging leadership and attention to detail to provide personalized support to residents in achieving their goals and annual targets, and maintaining detailed progress records.",
        "Ensured compliance with confidentiality and care standards, applying analytical thinking and strong communication to optimize patient care and foster trust within the community.",
      ],
    },
    {
      company: "Total Merchant",
      title: "Web Developer Intern",
      duration: "Apr 2021- May 2022",
      description: [
        "Frontend Development Proficiency: Managed and maintained an existing website by regularly updating content and ensuring optimal performance using HTML, CSS, and JavaScript to create responsive and user-friendly web interfaces.",
        "Problem-Solving and Debugging: Demonstrated strong problem-solving skills by debugging and resolving technical issues efficiently, ensuring seamless functionality and performance across all site components.",
        "Collaboration and Communication: Collaborated closely with the UI/UX team to enhance website design and user flow, effectively gathering feedback to implement cohesive and user-friendly improvements.",
        "Attention to Detail: Conducted careful reviews and refinements of code to maintain quality standards, contributing to a reliable and engaging user experience.",
        "Time Management and Adaptability: Assisted in deployment processes, coordinating with team members to roll out updates smoothly while prioritizing tasks and adapting to new challenges within a fast-paced environment to consistently meet project deadlines.",
      ],
    },
    {
      company: "Oakland Times",
      title: "Content Writer",
      duration: "Mar 2019 - Apr 2021",
      description: [
        "Applied strong communication and analytical thinking to produce engaging articles on University News, school sports, and entertainment, ensuring content was both informative and appealing to the university audience.",
        "Demonstrated attention to detail and problem-solving skills by thoroughly researching topics and verifying sources, maintaining accuracy and credibility in all published pieces.",
        "Consistently met tight deadlines by submitting an average of three articles per week, showcasing excellent time management and reliability in a fast-paced editorial environment.",
        "Collaborated with editorial teams and student contributors to align on content themes and deadlines, highlighting teamwork and collaboration to ensure a cohesive publication.",
        "Exercised leadership and creativity in generating fresh ideas for feature articles and unique angles for news stories, contributing to the newspaper’s diverse content offerings.",
        "Utilized critical thinking and adaptability to respond to editorial feedback quickly, refining content to meet publication standards and optimize reader engagement.",
      ],
    },
    // {
    //   company: "Nu Start",
    //   title: "Volunteer Web Developer",
    //   duration: "Jan 2018 - May 2021",
    //   description: [
    //     "Built and maintained company website using React",
    //     "Implemented responsive layouts and modern CSS practices",
    //     "Integrated third-party APIs and services",
    //     "Improved site performance and SEO",
    //   ],
    // },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      {
        threshold: 0.2,
      }
    );

    if (experienceRef.current) {
      observer.observe(experienceRef.current);
    }

    return () => {
      if (experienceRef.current) {
        observer.unobserve(experienceRef.current);
      }
    };
  }, []);

  return (
    <div className="experience" ref={experienceRef}>
      <div className="experience_title">
        <h3>/experience</h3>
      </div>
      <div className="experience_container">
        <div className="job_tabs">
          {jobs.map((job, index) => (
            <button
              key={index}
              className={`job_tab ${activeTab === index ? "active" : ""}`}
              onClick={() => setActiveTab(index)}
            >
              {job.company}
            </button>
          ))}
        </div>
        <div className="job_content">
          <div className="job_header">
            <h3>{jobs[activeTab].title}</h3>
            <h4>{jobs[activeTab].company}</h4>
            <p className="job_duration">{jobs[activeTab].duration}</p>
          </div>
          <ul className="job_description">
            {jobs[activeTab].description.map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
