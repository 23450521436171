import "./Projects.css";
import githubIcon from "../../assests/icons/github-svgrepo-com.svg";

export default function Projects() {
  const projects = [
    {
      title: "Emoji Swap Game",
      description:
        "A fun and interactive memory card game built with React and styled with Tailwind CSS. Players match pairs of emojis while racing against time and minimizing moves.",
      tech: ["React", "CSS"],
      github: "https://github.com/nayaA-io/Emoji-Swap",
      live: "https://project1.com",
    },
    {
      title: "TubeTrove",
      description:
        "Tube Trove is an application designed to help users curate, categorize, and interact with video content seamlessly. It's a platform for video lovers, creators, and content explorers, aiming to simplify the way they save and organize their favorite video resources.\n\n[ WORK IN PROGRESS 🚧 ]",
      tech: ["React", "CSS", "Node", "Express", "MongoDB"],
      github: "https://github.com/nayaA-io/",
      live: "https://project2.com",
    },
    {
      title: "To-do List",
      description:
        "A simple to-do list app built with React that allows users to add, edit, and delete tasks.",
      tech: ["React"],
      github: "https://github.com/nayaA-io/Todo",
      live: "https://project3.com",
    },
    {
      title: "Portfolio",
      description:
        "A personal portfolio website built with React that showcases my projects and skills.",
      tech: ["React"],
      github: "https://github.com/nayaA-io",
      live: "https://catherineanozie.com",
    },
  ];

  return (
    <div className="projects">
      <div className="projects_title">
        <h3>/projects</h3>
      </div>

      <div className="project_cards-grid">
        {projects.map((project, index) => (
          <div className="project_card" key={index}>
            <div className="project_card-content">
              <h3>{project.title}</h3>
              <p>{project.description}</p>
              <div className="project_tech-stack">
                {project.tech.map((tech, techIndex) => (
                  <span key={techIndex}>{tech}</span>
                ))}
              </div>
            </div>
            <div className="project_card-footer">
              <a
                href={project.github}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={githubIcon} alt="GitHub" className="github-icon" />
              </a>
              {/* <a
                href={project.live}
                target="_blank"
                rel="noopener noreferrer"
                className="live-link"
              >
                Live Demo
              </a> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
