import "./Footer.css";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer_content">
        <p>
          Built and designed by Catherine Anozie.
          <span className="copyright">
            All rights reserved. &copy; {new Date().getFullYear()}
          </span>
        </p>
      </div>
    </footer>
  );
}
